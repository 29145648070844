<template>
  <div class="patients">
    <b-row class="p-2">
      <b-col cols="6" class="ml-auto">
        <b-form-input 
          v-model="filter" 
          type="search" 
          placeholder="Type to Search" 
          :debounce="500">
        </b-form-input>
      </b-col>
    </b-row>

    <b-row>   
      <b-col cols="12">
        <b-table
          responsive show-empty striped hover 
          :items="this.fetchData"
          :fields="fields" 
          :current-page="currentPage"
          :per-page="10"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >        
          <template #cell(actions)="row">
            <b-icon :icon="`${row.detailsShowing 
              ? 'chevron-compact-up' 
              : 'chevron-compact-down'}`" 
              style="cursor: pointer;" 
              @click="row.toggleDetails">
            </b-icon>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2" v-if="loading">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #row-details="row">
            <b-card>
              <b-icon icon="cursor"></b-icon>
              <a style="cursor: pointer; color: #803321;" 
              @click="sendPasswordResetLink(row.item.id)"> 
                Send password reset link 
              </a>       
              <b-spinner 
                small 
                class="align-middle mr-3 text-danger"
                v-if="sendPasswordResetLinkLoading">
              </b-spinner>     
            </b-card>
          </template>
        </b-table>
        <b-pagination pills
          size="sm"
          v-model="currentPage"
          :total-rows="totalItemsCount"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import patientsService from '../../services/patients';

export default {
  name: 'Patients',
  data: () => {
    return {
      fields: [
        {
          key: 'fullName',
          label: 'Full name',
          sortable: true
        },
        {
          key: 'birthDate',
          label: 'Birth date',
          sortable: true
        },
        {
          key: 'emailAddress',
          label: 'Email',
          sortable: true
        },
        {
          key: 'patientInfoStatus',
          label: 'Patient Info',
          sortable: true
        },
        {
          key: 'medicalHistoryStatus',
          label: 'Med History',
          sortable: true
        },
        {
          key: 'consentCompleteStatus',
          label: 'Consent',
          sortable: true
        },
        {
          key: 'billingStatus',
          label: 'Billing Status',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions',
        }
      ],
      currentPage: 1,
      perPage: 10,
      totalItemsCount: 0,
      filter: null,
      sortBy: null,
      sortDesc: false,
      loading: false,
      sendPasswordResetLinkLoading: false
    };
  },
  methods: {
    async fetchData(params) {
      if (!params) params = { currentPage: this.currentPage, perPage: this.perPage };
      this.loading = true;
      const list = await patientsService.getList(params);
      this.loading = false;
      this.totalItemsCount = list.totalItemsCount;
      return list.items;
    },

    rowClicked(row) {
      this.$router.push(`/patients/${row.id}`);
    },

    sendPasswordResetLink: async function (id) {
      if (!id) {
        return;
      }

      this.sendPasswordResetLinkLoading = true;
      let passwordResetMessage = await patientsService.sendPasswordResetLink(id);
      this.sendPasswordResetLinkLoading = false;
  
      if (passwordResetMessage) this.makeToast(
        passwordResetMessage.message,
        'Success',
        'success'  
      );      
    },

    makeToast(message, title, type) {
      this.$bvToast.toast(message, {
        title: title,
        variant: type,
        solid: true,
        noCloseButton: true
      })
    }
  }
}
</script>